export const abbreviateNumber = (value) => {
    let newValue = Math.abs(value);
    if (value >= 1000) {
        let suffixes = ["", "K", "M", "B", "T"];
        let suffixNum = Math.floor(("" + value).length / 3);
        let shortValue = '';
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
            let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
}

export const formatNumber = (num) => {
    let nf = Intl.NumberFormat()
    return nf.format(num)
}


export const formatVoteData = (votesFor, votesAgainst) => {
    let hasVotes = false
    votesFor = Number(votesFor)
    votesAgainst = Number(votesAgainst)
    let votesTotal = votesFor + votesAgainst
    let votesDiff = votesFor - votesAgainst
    let votesDiffRaw = votesDiff;

    let votesPercentFor = 0
    let votesPercentAgainst = 0
    if (votesTotal > 0) {
        hasVotes = true
        votesPercentFor = Number((votesFor / votesTotal * 100).toFixed(1));
        votesPercentAgainst = Number((votesAgainst / votesTotal * 100).toFixed(1));
    }
    //format the rest
    votesFor = formatNumber(votesFor)
    votesAgainst = formatNumber(votesAgainst)
    votesTotal = formatNumber(votesTotal)
    votesDiff = formatNumber(votesDiff)

    return { hasVotes, votesFor, votesAgainst, votesTotal, votesDiff, votesDiffRaw, votesPercentFor, votesPercentAgainst }

}