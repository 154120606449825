<template>
  <div v-if="showNoToken" class="bg-warning-100 border-l-4 border-warning-400 rounded-r-full text-warning-500 p-4">
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <ExclamationIcon class="h-12 w-12"></ExclamationIcon>
      </div>
      <div class="ml-3 flex-grow">
        <h4 class="text-warning-600 text-2xl">
          This account has no <span class="font-bold">${{ balance.symbol }} tokens</span>
        </h4>
        <p>You will not be able to see or vote on any proposals</p>
      </div>
      <div class="flex-shrink-0">
        <IconLogo class="w-12 h-12 text-warning-200"></IconLogo>
      </div>
    </div>
  </div>
  <div v-if="!showNoToken" class="bg-primary-50 border-l-4 border-primary-400 rounded-r-full text-primary-500 p-4">
    <div class="flex items-center">
      <div class="ml-3 flex-grow">
        <div v-if="!hasLoaded" class="flex items-center">
          Fetching tokens
          <AppLoader size-class="h-4 w-4"></AppLoader>
        </div>
        <div v-if="hasLoaded" class=" text-lg  font-ubuntu">
          <div class="flex items-center justify-between text-primary-300">
            <div class=" flex items-center">
              <span class="mr-1">Token holding:</span>
              <span class="text-xl font-bold mr-1">{{ formattedTokens }}</span>
              <span class="text-lg mr-1">${{ balance.symbol }}</span>
            </div>
          </div>
          <div class="flex items-center justify-between text-primary-500">
            <div class=" flex items-center">
              <span class="mr-1">Staked Tokens:</span>
              <span class="text-3xl font-bold mr-1">{{ formattedStakedTokens }}</span>
              <span class="text-2xl mr-1">${{ balance.symbol }}</span>
              <a href="https://portal.photochromic.io/" target="_blank" class="btn ml-4" v-if="notStaked">Stake tokens</a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0">
        <IconLogo class="w-12 h-12 text-primary-100 "></IconLogo>
      </div>
    </div>
  </div>
  <div v-if="showNoToken">
    <div class="w-full mt-12 flex justify-center">
      <a href="https://www.huobi.com/en-us/exchange/phcr_usdt/" target="_blank" class="btn">Buy $PHCR here</a>
    </div>
  </div>
</template>
<script>
import { computed, inject, onMounted, ref, watch } from "vue";
import contractFunctions from '../utils/crypto/contractFunctions'
import stakingFunctions from '../utils/crypto/stakingFunctions'
import { ExclamationIcon } from '@heroicons/vue/solid'
import AppLoader from "@/components/AppLoader";
import IconLogo from "@/components/IconLogo";
import { formatNumber } from "@/utils/numbers";


export default {
  props: {},
  components: { IconLogo, AppLoader, ExclamationIcon },
  setup()
  {
    const wallet = ref(null)
    const balance = ref(null)
    const showNoToken = ref(false)
    const hasLoaded = ref(false)
    const notStaked = ref(false)

    const staking = ref({})

    const canVote = inject('canVote')

    const connectedAccount = inject('connectedAccount')
    const hasTokens = inject('hasTokens')

    const formattedTokens = computed(() => {
      if (balance.value) {
        let pre = formatNumber(balance.value.natural);
        if (balance.value.decimal > 0) {
          return pre + '.' + balance.value.decimal
        }
        return pre;
      }
      return 0;
    })

    const formattedStakedTokens = computed(() => {
      if (staking.value) {
        let pre = formatNumber(staking.value.natural);
        if (staking.value.decimal > 0) {
          return pre + '.' + staking.value.decimal
        }
        return pre;
      }
      return 0;
    })

    watch(connectedAccount, async () => {
      await initWallet()
    })

    const initWallet = async () => {
      hasLoaded.value = false
      showNoToken.value = false

      if (connectedAccount.value) {
        balance.value = await contractFunctions.tokenBalance()
        staking.value = await stakingFunctions.getTokensStaked()
        hasTokens.value = true
        if (staking.value.isNull && balance.value.isNull) {
          hasTokens.value = false
          showNoToken.value = true
        }
        if (staking.value.isNull) {
          notStaked.value = true;
        } else {
          canVote.value = true;

        }

      }


      hasLoaded.value = true
    }


    onMounted(async () => {
      await initWallet()
    })

    return { wallet, balance, hasTokens, hasLoaded, formattedTokens, showNoToken, formattedStakedTokens, notStaked }
  }
}
</script>