<template>
  <div class="px-4 py-4 sm:px-6">
    <div class="flex items-center">
      <div class="flex mr-4">
        <AppVoteStatusIcon :score="score"></AppVoteStatusIcon>
      </div>
      <div class="flex-grow">
        <div class="flex items-center justify-between">
          <p class="text-sm font-medium text-gray-600 text-xl font-light truncate">
            {{ title }}
            <span v-if="isClosed" class="font-extrabold text-sm">[closed]</span>
          </p>
          <div class="ml-2 flex-shrink-0 flex">
            <AppBadge v-if="!isClosed" :type="status.type" class="shadow">
              <ClockIcon class="w-4 h-4 mr-1" v-if="isEndingSoon"></ClockIcon>
              {{ status.title }}
            </AppBadge>
          </div>
        </div>
        <div class="mt-2 sm:flex sm:justify-between">
          <div class="sm:flex">
            <div v-if="tags">
              <AppBadge v-for="tag in tags" :key="tag" class="shadow mr-4 text-gray-400 uppercase">
                <TagIcon class="h-4 w-4 mr-1"></TagIcon>
                {{ tag }}
              </AppBadge>
            </div>
          </div>
          <div class="mt-2 flex items-center text-xs text-gray-400 sm:mt-0">
            <CalendarIcon class="w-5 h-5 mr-1"></CalendarIcon>
            <div class="font-light">
              <span v-if="!isClosed">Closing on</span>
              <span v-if="isClosed">Closed on</span>
              <time class="ml-1 font-bold" datetime="2020-01-07">{{ date }}</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppBadge from "@/components/AppBadge";
import { TagIcon, CalendarIcon, ClockIcon } from '@heroicons/vue/outline'
import AppVoteStatusIcon from "@/components/AppVoteStatusIcon";

export default {
  components: { AppVoteStatusIcon, AppBadge, CalendarIcon, TagIcon, ClockIcon },
  props: {
    title: {},
    score: {
      type: Number,
      default: 0
    },
    status: {
      default: {
        title: 'Running',
        type: 'success'
      }
    },
    isClosed: {
      type: Boolean,
      default: false
    },
    isEndingSoon: {
      type: Boolean,
      default: false
    },
    date: {},
    tags: {
      type: Array,
    }
  },
  setup()
  {

  }
}
</script>