<template>
  <AppLoader v-if="isLoading"></AppLoader>
  <div v-if="!isLoading">
    <AppError v-if="hasError" :message="hasError"></AppError>
    <div v-if="!hasError">
      <AppBreadcrumbs :here="proposalData.title"></AppBreadcrumbs>
      <div class="card overflow-hidden">
        <div class="card-section flex justify-between items-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ proposalData.title }}
          </h3>
          <div v-if="!proposalData.isClosed">
            <AppBadge :type="proposalData.status.type">{{ proposalData.status.title }}</AppBadge>
          </div>
          <div v-if="proposalData.isClosed">[Closed]</div>
        </div>
        <div class="card-section border-t">
          <p class="leading-6 font-medium text-gray-600">
        <span class="whitespace-pre-wrap">
          {{ proposalData.description }}
        </span>
          </p>
        </div>
        <div class="card-section shadow-inner  border-xl border-gray-100 bg-gray-50">
          <div class="flex  font-ubuntu text-sm ">
            <div class="flex-grow">
              <!--  For -->
              <div class="flex items-center text-success-500 h-8">
                <div class="flex-shrink w-24 text-right pr-2">
                  <div class="flex justify-end items-center">
                    <span>For</span>
                    <ThumbUpIcon class="h-4 w-4 ml-1"></ThumbUpIcon>
                  </div>
                </div>
                <div class="flex-grow">
                  <AppProgressBar :percent="voteData.votesPercentFor" color="success"></AppProgressBar>
                </div>
                <div class="flex-shrink pl-2  w-24">{{ voteData.votesPercentFor }}%</div>
              </div>
              <!--  Against -->
              <div class="flex items-center text-danger-500  h-10">
                <div class="flex-shrink w-24 pr-2">
                  <div class="flex justify-end items-center">
                    <span>Against</span>
                    <ThumbDownIcon class="h-4 w-4 ml-1"></ThumbDownIcon>
                  </div>
                </div>
                <div class="flex-grow">
                  <AppProgressBar :percent="voteData.votesPercentAgainst" color="danger"></AppProgressBar>
                </div>
                <div class="flex-shrink pl-2 w-24">{{ voteData.votesPercentAgainst }}%</div>
              </div>
              <!--  End -->
            </div>
          </div>
        </div>
        <div class="card-section ">
          <div class="flex items-center">
            <div class="leading-6 font-light text-sm text-gray-600 flex-grow">
              <div v-if="!proposalData.isClosed">Closes on
                <time :datetime="proposalData.closingDate" class="font- font-bold">{{ proposalData.closingDateFormatted }}</time>
              </div>
              <div class="flex justify-between w-40">
                <div>
                  For: <br/>
                  Against: <br/>
                  Total&nbsp;Votes:
                </div>
                <div class="text-right">
                  <div><span class="text-success-700 font-bold">{{ voteData.votesFor }}</span></div>
                  <div><span class="text-danger-600 font-bold">{{ voteData.votesAgainst }}</span></div>
                  <div><span class="text-black font-extrabold">{{ voteData.votesTotal }}</span></div>
                </div>
              </div>
            </div>
            <div class="flex-shrink ml-4">
              <AppVoteStatusIcon :score="voteData.votesDiffRaw"></AppVoteStatusIcon>
            </div>
          </div>
        </div>
        <div v-if="!canVote">
          <div class="bg-primary-900 text-white border-t flex items-center justify-center">
            <div class="card-section flex flex-col items-center">
              <div class="text-lg">To vote on this proposal you will need to have staked PHCR tokens</div>
              <div class="text-primary-300">Your vote will be weighted to the number of tokens staked</div>
              <a href="https://portal.photochromic.io/" target="_blank" class="mt-4 btn">Stake tokens</a></div>
          </div>
        </div>
        <div v-if="canVote">
          <!--  Account vote status none -->
          <div v-if="!proposalData.isClosed">
            <div class="bg-primary-900 text-white border-t flex items-center" v-if="voteStatus.outcome === null">
              <div class="card-section flex-grow text-right">You have not voted yet, vote now -></div>
              <div class="card-section shadow flex-shrink flex flex-col items-center bg-app-gradient">
                <div class="font-bold">Are you in favor of this proposal?</div>
                <div>
                  <button class="btn-secondary ml-4" @click="toggleShow('yes')">Yes</button>
                  <button class="btn-secondary ml-4" @click="toggleShow('no')">No</button>
                </div>
              </div>
            </div>
          </div>
          <!--  Account vote status for -->
          <div class="bg-success-900 text-success-300 border-t flex items-center" v-if="voteStatus.outcome === 'for'">
            <div class="card-section flex-grow text-center">You voted
              <span class="font-bold text-success-200">yes</span>
              for this proposal on
              <span class="font-bold text-success-200">{{ voteStatus.date }}</span>
            </div>
          </div>
          <!--  Account vote status against -->
          <div class="bg-danger-900 text-danger-300 border-t flex items-center" v-if="voteStatus.outcome === 'against'">
            <div class="card-section flex-grow text-center">You voted
              <span class="font-bold text-danger-200">no</span>
              for this proposal on
              <span class="font-bold text-danger-200">{{ voteStatus.date }}</span>
            </div>
          </div>
          <!--  /Account vote status -->
        </div>
      </div>
      <!-- Modals-->
      <AppModalConfirm :open="showConfirmationYes" @closed="toggleShow('yes')" @confirmed="triggerConfirmation('yes')" confirmation-btn="Vote Yes">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-success-400">
          <ThumbUpIcon class="h-6 w-6 text-success-100" aria-hidden="true"/>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Vote <span class="text-success-500">yes</span> for this proposal?
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Please confirm that you are <span class="text-success-500"> in favor</span> of: <br/>
              <span class="text-black font-bold">Offer staking to token holders</span>
            </p>
          </div>
        </div>
      </AppModalConfirm>
      <AppModalConfirm :open="showConfirmationNo" @closed="toggleShow('no')" @confirmed="triggerConfirmation('no')" confirmation-btn="Vote No">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-danger-400">
          <ThumbDownIcon class="h-6 w-6 text-danger-100" aria-hidden="true"/>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Vote <span class="text-danger-500">no</span> for this proposal?
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Please confirm that you are <span class="text-danger-500">not in favor</span> of: <br/>
              <span class="text-black font-bold">Offer staking to token holders</span>
            </p>
          </div>
        </div>
      </AppModalConfirm>
      <!-- / Modals-->
    </div>
  </div>
</template>
<script>
import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import AppVoteStatusIcon from "@/components/AppVoteStatusIcon";
import AppProgressBar from "@/components/AppProgressBar";
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/vue/outline";
import AppBadge from "@/components/AppBadge";
import AppModalConfirm from "@/components/AppModalConfirm";
import { inject, onMounted, reactive, ref, onUpdated } from "vue";
import { formatVoteData } from "@/utils/numbers";
// import wait from "@/utils/wait";
import Proposal from "@/services/api/Proposals";
import AppLoader from "@/components/AppLoader";
import AppError from "@/components/AppError";


export default {
  components: {
    AppError,
    AppLoader,
    AppModalConfirm,
    AppBadge,
    AppProgressBar,
    AppVoteStatusIcon,
    AppBreadcrumbs,
    ThumbDownIcon,
    ThumbUpIcon
  },
  props: {},
  setup()
  {
    const emitter = require('tiny-emitter/instance');
    const proposalData = reactive({})
    const accessToken = inject('accessToken')
    const route = inject('route')
    const canVote = inject('canVote')
    const isLoading = ref(true)
    const hasError = ref(false)

    const proposalId = route.params.id;

    const voteData = reactive({})

    const voteStatus = reactive({
      outcome: null,
    })

    const showConfirmationYes = ref(false)
    const showConfirmationNo = ref(false)

    const toggleShow = (type) => {
      if (type === 'yes') {
        showConfirmationYes.value = !showConfirmationYes.value
      }
      if (type === 'no') {
        showConfirmationNo.value = !showConfirmationNo.value
      }

    }

    const triggerConfirmation = async (type) => {
      isLoading.value = true
      if (type === 'yes') {
        showConfirmationYes.value = false;
        let voted = await Proposal.vote(proposalId, 1, accessToken.value)
        if (voted.success) {

          await fetchProposalData()
        } else {
          emitter.emit('clear-storage')
          hasError.value = voted.meta
        }


      }
      if (type === 'no') {
        showConfirmationNo.value = false;
        let voted = await Proposal.vote(proposalId, -1, accessToken.value)
        if (voted.success) {
          await fetchProposalData()
        } else {
          emitter.emit('clear-storage')
          hasError.value = voted.meta
        }

      }
    }

    const updateNumbers = () => {
      if (accessToken.value) {
        Object.assign(voteData, formatVoteData(
            proposalData.vote.votesFor,
            proposalData.vote.votesAgainst
        ))
      }

    }

    const updateVoteStatus = async () => {
      if (accessToken.value) {
        let vote = await Proposal.getVoteStatus(proposalId, accessToken.value)
        if (vote.success) {
          Object.assign(voteStatus, vote.data)
        } else {
          emitter.emit('clear-storage')
          hasError.value = vote.meta
        }
        return
      }
      hasError.value = 'Your account does not have access'

    }

    const fetchProposalData = async () => {
      isLoading.value = true;
      if (!accessToken.value) {
        hasError.value = 'No access token detected'
        isLoading.value = false;
        return
      }


      let result = await Proposal.getProposal(proposalId, accessToken.value)
      if (result.success) {
        Object.assign(proposalData, result.data)
        await updateVoteStatus();
        updateNumbers()
      } else {
        //error
        emitter.emit('clear-storage')
        hasError.value = result.meta
      }
      isLoading.value = false
    }

    onMounted(async () => {
      await fetchProposalData()
    })

    onUpdated(async () => {

      // await fetchProposalData()
    })

    return {
      proposalData,
      voteStatus,
      showConfirmationYes,
      showConfirmationNo,
      toggleShow,
      triggerConfirmation,
      isLoading,
      voteData,
      hasError,
      canVote
    }
  }
}
</script>