<template>
  <span class="inline-flex items-center rounded-full " :class="[colorClasses,sizeClasses]"><slot/></span>
</template>
<script>
import { computed } from "vue";

export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'default'
    },
    colorClass: {
      type: String,
      default: ''
    },
    sizeClass: {
      type: String,
      default: ''
    }

  },
  setup(props)
  {
    const colorClasses = computed(() => {
      if (props.colorClass) {
        return props.colorClass
      }
      switch (props.type) {
        case 'primary':
          return 'bg-primary-100 text-primary-800';
        case 'secondary':
          return 'bg-secondary-100 text-secondary-800';
        case 'success':
          return 'bg-success-100 text-success-800';
        case 'info':
          return 'bg-info-100 text-info-800';
        case 'warning':
          return 'bg-warning-100 text-warning-800';
        case 'danger':
          return 'bg-danger-100 text-danger-800';
        default:
          return 'bg-gray-100 text-gray-500';
      }
    })
    const sizeClasses = computed(() => {
      if (props.sizeClass) {
        return props.sizeClass;
      }
      switch (props.size) {
        case 'md': {
          return 'px-3 py-1 text-md font-medium'
        }
        case 'lg': {
          return 'px-3 py-1 text-lg font-light'
        }

        default:
          return 'px-2.5 py-0.5 text-xs font-medium';
      }
    })

    return { colorClasses, sizeClasses }
  },
}
</script>
