<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 182 182" style="enable-background:new 0 0 182 182;" xml:space="preserve">
    <g>
	<path fill="currentColor" d="M91,0C40.7,0,0,40.7,0,91c0,50.3,40.7,91,91,91s91-40.7,91-91C182,40.7,141.3,0,91,0z M130.9,98.8
		c-8.2,12.4-20,18.4-33.3,20.1v26.2c0,4.1-3.4,7.5-7.5,7.5c-4.1,0-7.5-3.4-7.5-7.5v-26.1c-8-1.2-15.5-4.3-21.6-8.6
		c-7.8-5.5-14.1-13.7-15.3-23.1c-1.2-9.7,1.5-23.7,14.6-29.4c3.8-1.7,8.2,0.1,9.9,3.9c1.7,3.8-0.1,8.2-3.9,9.9
		c-4.3,1.9-6.6,7.2-5.8,13.9c0.5,3.9,3.5,8.7,9.1,12.7c3.7,2.6,8.2,4.6,12.9,5.6V63.3c0-9,2.6-17.3,8.6-23.1
		c6.2-5.9,14.7-7.8,23.9-6.4c14.7,2.3,20.8,16.6,22.5,28.4C139.4,74.6,137.3,89.2,130.9,98.8z"/>
      <path fill="currentColor" d="M112.8,48.7c-5.8-0.9-9.3,0.5-11.2,2.4c-2.1,2-3.9,5.9-3.9,12.2v40.4c8.9-1.5,15.9-5.7,20.8-13.2
		c3.8-5.7,5.8-16.3,4.4-26.1C121.3,54.1,117,49.4,112.8,48.7z"/>
</g>
</svg>
</template>
