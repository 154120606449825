import axios from 'axios'

let result = {
    success: false,
    code: 200,
    meta: 'OK',
    data: {}
};

export default {


    getProposals(token, ip)
    {

        return axios.get('/proposals', { params: { token, ip } })
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },

    getProposal(proposalId, token, ip)
    {

        return axios.get('/proposal/' + proposalId + '/view', { params: { token, ip } })
            .then(response => {

                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },
    getVoteStatus(proposalId, token, ip)
    {
        return axios.get('/proposal/' + proposalId + '/vote-status', { params: { token, ip } })
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },
    vote(proposalId, direction, token, ip)
    {

        return axios.post('/proposal/' + proposalId + '/vote', { direction }, { params: { token, ip } })
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },

}
