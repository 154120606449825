<template>
  <main class="sm:flex mt-28">
    <p class="text-4xl font-extrabold text-primary-400 sm:text-5xl">404</p>
    <div class="sm:ml-6">
      <div class="sm:border-l sm:border-gray-200 sm:pl-6">
        <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Not found</h1>
        <p class="mt-1 text-base text-gray-500"> Please check the URL in the address bar and try again.</p>
      </div>
      <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
        <router-link to="/" class="inline-flex items-center btn-secondary">
          Go back home
        </router-link>
      </div>
    </div>
  </main>
</template>
