const addresses = {
    ropsten: {
        token: '0x48a3ffF68042998E6BAA341c084309D8e0d187df',
        mbl1: '0xcb8a0754626863187b1F4E7B09Ee351eE1bD6CAC',
        mbl2: '0x8870E4Ff135a18d147544E20635C3464DacDF8c3',
        mbl3: '0xbd518e284f04d04ff3ee4881a464e0cd6f6449bd',
        mbl4: '',
        staking: '',
    },
    rinkeby: {
        mbl1: '0xcb8a0754626863187b1F4E7B09Ee351eE1bD6CAC',
        mbl2: '0x8870E4Ff135a18d147544E20635C3464DacDF8c3',
        mbl3: '0xbd518e284f04d04ff3ee4881a464e0cd6f6449bd',
        mbl4: '',
        // erc20: '0x257052Aae0813e667a150c0b4FD950d04961E5c0',
        token: '0xea9f188a0187fbafab3953edfce5386b101fe5ab',
        staking: '0xc34D15F3d4a73587249F90c65d35386D21f5b495',
    },
    mainnet: {
        token: '0x37E83a94c6B1Bdb816B59aC71dd02CF154d8111F',
        mbl1: '0xc32a523fa5c7ff63548da4567c6308c6dcb4f552',
        mbl2: '0xb7056c1065B53c02F6104BE771D80F98BF4f67e5',
        mbl3: '0xF3d7aa957F5Cd4e3b8947b6acb88f2B8fd9A2e88',
        mbl4: '0x6de652344fd5db5b44dcfb8e52556cc1abdbbfa8',
        // staking: '0x2d3d2F1540e9F316E4B91963263d4dF9d1Eb08f3',
        staking: '0xb955C646A81eeA7Fffb5B5F4A3D66Da4eE285d86',
    }
}

const getAddress = (network, name) => addresses[network][name]

const $ = {
    getAddress
}

export default $