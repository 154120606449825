<template>
  <div class="relative">
    <div class="absolute w-1 bg-transparent border-opacity-50 border-l border-white top-0 left-1/2 block h-4"></div>
    <div class="overflow-hidden h-4 text-xs flex rounded-full" :class="barClass">
      <div :style="lengthStyle" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition-all duration-300" :class="meterClass"></div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";


export default {
  props: {
    percent: {
      type: Number,
      default: 0
    },
    color: {}
  },

  setup(props)
  {

    const barClass = ref('bg-primary-200')
    const meterClass = ref('bg-primary-500')
    const lengthStyle = computed(() => {
      return 'width: ' + String(props.percent) + '%'
    })

    switch (props.color) {
      case 'danger':
        barClass.value = 'bg-danger-200'
        meterClass.value = 'bg-danger-500'
        break;
      case 'success':
        barClass.value = 'bg-success-200'
        meterClass.value = 'bg-success-500'
        break;
      case 'secondary':
        barClass.value = 'bg-secondary-200'
        meterClass.value = 'bg-secondary-500'
        break;
    }

    return { barClass, meterClass, lengthStyle }


  }
}
</script>