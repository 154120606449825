<template>
  <div>
    <TheLayout>
      <div class="mt-4" v-if="connectedAccount">
        <TheWalletBalance></TheWalletBalance>
      </div>
      <div v-if="isLoading">
        <AppLoader>Loading</AppLoader>
      </div>
      <div class="mt-4" v-if="!isLoading">
        <div v-if="hasError">
          <AppError :message="hasError"></AppError>
        </div>
        <div v-if="!hasError">
          <router-view></router-view>
        </div>
      </div>
    </TheLayout>
    <AppAlert @show="(showVal) => alertMessage.show	= showVal" :type="alertMessage.type" :details="alertMessage.details" :show="alertMessage.show">{{ alertMessage.title }}</AppAlert>
  </div>
</template>
<script>
import TheLayout from "@/components/TheLayout";
import { provide, ref, reactive, onMounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from 'vue-router'
import connection from "./utils/crypto/connection";
import contractFunctions from "./utils/crypto/contractFunctions";
import AppLoader from "@/components/AppLoader";
import TheWalletBalance from "@/components/TheWalletBalance";
import Tokens from "@/services/api/Tokens";
import AppError from "@/components/AppError";


const AppAlert = defineAsyncComponent(() =>
    import('./components/AppAlert.vue')
)
const emitter = require('tiny-emitter/instance');

export default {
  name: 'App',
  components: { AppError, TheWalletBalance, AppLoader, TheLayout, AppAlert },
  setup()
  {
    const connectedAccount = ref(null)
    const accessToken = ref(false)
    const hasTokens = ref(false)
    const hasError = ref(null)
    const isLoading = ref(true)
    const canVote = ref(false)

    const router = useRouter()
    const route = useRoute()

    provide('connectedAccount', connectedAccount)
    provide('router', router)
    provide('route', route)
    provide('hasTokens', hasTokens)
    provide('accessToken', accessToken)


    provide('canVote', canVote)

    const authAccount = async (accountAddress) => {

      if (!accessToken.value) {
        //Connect account
        let tokenCheck = localStorage.getItem('session[' + accountAddress + ']')
        if (tokenCheck) {
          accessToken.value = tokenCheck
          return
        }
        let ipMark = await Tokens.markIp();
        if (!ipMark.success) {

          hasError.value = 'We could not detect an IP, please refresh to try again'
          return
        }
        //Request key
        let keyRequest = await Tokens.initSession(accountAddress, ipMark.data.ip)
        if (!keyRequest.success) {
          hasError.value = 'Unexpected API error. Please refresh and try again'
          return
        }
        let key = keyRequest.data.sessionId;
        //Ask to sign
        let signature = await connection.handleSignMessage({ publicAddress: accountAddress, nonce: key })
        //Valid or no
        if (!signature) {
          hasError.value = 'No verification received from your MetaMask'
          return
        }
        let validatedSignature = await Tokens.signToken(key, signature)
        if (!validatedSignature.success) {
          hasError.value = validatedSignature.message
          return
        }
        let token = validatedSignature.data.token;
        //All good, set access token
        accessToken.value = token;
        localStorage.setItem('session[' + accountAddress + ']', token)

      }
      return true;

    }

    async function setAccount()
    {
      let acc = await connection.getAccount();

      if (acc) {
        connectedAccount.value = acc.short
        let balance = await contractFunctions.tokenBalance()
        if (!balance.isNull) {
          await authAccount(acc.full)
        }
      } else {
        connectedAccount.value = null;
        await router.push('/connect')
      }

    }


    // const setAccessToken = async () => {
    //   if (!accessToken.value) {
    //     //check local
    //     let acc = await connection.getAccount();
    //
    //
    //     let check = localStorage.getItem('session[' + acc + ']')
    //     if (check) {
    //       accessToken.value = check
    //
    //
    //       return
    //     }
    //
    //     let balance = await lockFunctions.tokenBalance()
    //     if (acc) {
    //       if (!balance.isNull) {
    //         //Need Auth here
    //
    //         let result = await Tokens.createSession(acc.full, ip.value)
    //         if (result.success) {
    //           accessToken.value = result.data.accessToken;
    //
    //           localStorage.setItem('session[' + acc + ']', accessToken.value)
    //
    //           return
    //         }
    //       }
    //
    //
    //     }
    //     accessToken.value = null;
    //   }
    // }

    async function metaMaskListeners()
    {
      window.ethereum.on('accountsChanged', async () => {
        isLoading.value = true
        hasTokens.value = false
        await setAccount();
        isLoading.value = false
      });
    }

    const removeLocalStorage = async () => {
      let acc = await connection.getAccount();
      localStorage.removeItem('session[' + acc.full + ']')
    }

    //Alerts
    const alertMessage = reactive({
      show: false,
      title: null,
      details: null,
      type: null,
    })


    const triggerAlert = async (title, type, details, waitTime) => {
      alertMessage.show = true
      alertMessage.title = title
      alertMessage.type = type
      alertMessage.details = details

      await connection.wait(waitTime)
      alertMessage.show = false
    }

    emitter.on('fire-alert', async function(title, type, details = null, waitTime = 3000){
      await triggerAlert(title, type, details, waitTime)
    });

    emitter.on('clear-storage', async function(){
      await removeLocalStorage()
    });


    onMounted(async () => {

          await setAccount()
          await metaMaskListeners()
          isLoading.value = false
        }
    )

    return { alertMessage, isLoading, connectedAccount, hasError }

  }
}
</script>
<style>
</style>