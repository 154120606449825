<template>
  <div class="mt-4" v-if="hasTokens">
    <AppDivider class="text-xl" bg-class="bg-gray-50">Proposals</AppDivider>
    <AppLoader v-if="isLoading"></AppLoader>
    <AppError v-if="proposalError" message="There was an unexpected error loading proposals"></AppError>
    <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="!isLoading">
      <ul role="list" class="divide-y divide-gray-200" v-if="proposals">
        <li v-for="proposal in proposals" :key="proposal">
          <router-link :to="router.resolve({ name: 'proposal-view', params: { id: proposal._id } }).href" class="block hover:bg-gray-50">
            <AppProposalItem
                :title="proposal.title"
                :status="{title: proposal.status.title, type: proposal.status.type}"
                :tags="proposal.tags"
                :score="proposal.score"
                :date="proposal.closingDateFormatted"
                :is-closed="proposal.isClosed"
            ></AppProposalItem>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { inject, onMounted, reactive, ref } from "vue";

import AppDivider from "@/components/AppDivider";
import AppProposalItem from "@/components/AppProposalItem";
import Proposal from '@/services/api/Proposals'
import AppLoader from "@/components/AppLoader";
import AppError from "@/components/AppError";
// import wait from "@/utils/wait";

export default {
  components: { AppError, AppLoader, AppProposalItem, AppDivider },
  props: {},
  setup()
  {
    const emitter = require('tiny-emitter/instance');
    const isLoading = ref(true)
    const hasTokens = inject('hasTokens', false)
    const connectedAccount = inject('connectedAccount')
    const accessToken = inject('accessToken')
    const router = inject('router')
    const proposals = reactive({})
    const proposalError = ref(false)

    const getProposalData = async () => {

      if (accessToken.value) {
        let result = await Proposal.getProposals(accessToken.value)
        if (!result.success) {
          proposalError.value = true
          emitter.emit('clear-storage')
          return
        }
        Object.assign(proposals, result.data)
      }


    }


    onMounted(async () => {
      if (!connectedAccount.value) {
        emitter.emit('fire-alert', 'Please connect', 'info')
        await router.push('/connect')
      }

      await getProposalData()
      isLoading.value = false
    })

    return { isLoading, hasTokens, router, proposals, proposalError }
  }
}
</script>