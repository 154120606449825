<template>
  <nav class="flex mb-4" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <router-link class="text-gray-400 hover:text-gray-500" to="/">
            <HomeIcon class="flex-shrink h-5 w-5"></HomeIcon>
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb">
        <div class="flex items-center">
          <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
          </svg>
          <a :href="breadcrumb.url" v-if="breadcrumb.url" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{ breadcrumb.label }}</a>
          <span v-if="!breadcrumb.url" class="ml-4 text-sm font-medium text-gray-500">{{ breadcrumb.label }}</span>
        </div>
      </li>
    </ol>
  </nav>
</template>
<script>
import { HomeIcon } from '@heroicons/vue/outline'

export default {
  props: {
    links: {},
    here: {}
  },
  components: { HomeIcon },
  setup(props)
  {
    const breadcrumbs = []

    if (props.links) {
      for (let link in props.links) {
        breadcrumbs.push({
          url: link.url,
          label: link.label
        })
      }
    }

    breadcrumbs.push({
      url: false,
      label: props.here
    })


    return { breadcrumbs }
  }
}
</script>