<template>
    <div>
        ABOUT
    </div>
</template>
<script>
export default {
    props: {},
    setup()
    {

    }
}
</script>