<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative py-2">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"/>
    </div>
    <div class="relative flex justify-center">
      <span class="px-2 text-gray-500" :class="bgClass"><slot/></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgClass: {
      type: String,
      default: 'bg-white'
    }
  },
  setup()
  {

  }
}
</script>
