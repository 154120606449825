import Contract from './contract'
import addresses from './settings/addresses'

const wait = (ms) => new Promise(resolve => setTimeout(() => resolve(), ms))

let decimals = -1

const tokenSettings = {
    decimals: 18,
    symbol: 'PHCR',
}


const tokenBalance = async () => {
    // await wait(2000)
    let contract = token()
    let acc = await getAcc()
    try {
        let balance = await contract.balanceOf([acc])
        return formatAmount(balance)
    }catch (e){
        return  formatAmount(0);
    }

};


const token = () => {
    let tokenAddress = addresses.getAddress(getNetwork(), 'token')
    let token = new Contract('token', tokenAddress)
    return token
}

const getAcc = async () => {
    let accs = await window.web3.eth.getAccounts();
    return accs[0];
}


const formatAmount = (inputAmount) => {


    let data = {
        symbol: tokenSettings.symbol,
        full: '0',
        natural: '0',
        decimal: '0',
        isNull: true,
    };


    let amountInt = parseInt(inputAmount)
    if (amountInt > 0) {
        const BN = window.web3.utils.BN
        const balanceWeiString = inputAmount.toString()
        const balanceWeiBN = new BN(balanceWeiString)

        const decimalsBN = new BN(tokenSettings.decimals)
        const divisor = new BN(10).pow(decimalsBN)

        const natural = balanceWeiBN.div(divisor).toString()
        const decimal = balanceWeiBN.mod(divisor).toString()


        data = {
            symbol: tokenSettings.symbol,
            full: natural + '.' + decimal,
            decimal,
            natural,
            isNull: false,
        };
    }

    return data;

}



const getDecimals = async () => {
    if (decimals > -1) return decimals
    let _token = token()
    decimals = await _token.decimals()
    return decimals
}



const getNetwork = () => {
    if (window.ethereum.networkVersion === '1') return 'mainnet'
    if (window.ethereum.networkVersion === '3') return 'ropsten'
    if (window.ethereum.networkVersion === '4') return 'rinkeby'
    if (window.$defaultNetwork === 1) return 'mainnet'
    if (window.$defaultNetwork === 3) return 'ropsten'
    if (window.$defaultNetwork === 4) return 'rinkeby'
}



const getAccount = async () => {
    let accs = await window.web3.eth.getAccounts();
    let full = accs[0];
    let short = '';
    if (full) {
        let prefix = full.substr(0, 5)
        let suffix = full.substr((full.length - 4), 4)
        short = prefix + '...' + suffix;
    }

    return { full, short }
}


const $ = {
    wait,
    formatAmount,
    getDecimals,
    tokenBalance,
    getNetwork,
    getAccount,

}

export default $