<template>
  <div class="flex flex-col h-screen">
    <div class="container mx-auto max-w-3xl px-4 subpixel-antialiased text-gray-800 flex-grow">
      <header class="flex justify-between items-center py-4">
        <div>
          <router-link to="/">
            <img src="../assets/img/logo.png" alt="Photochromic" class="w-44 p-1 "/>
          </router-link>
        </div>
        <div>
          <TheConnectedElement></TheConnectedElement>
        </div>
      </header>
      <main class="mb-12">
        <slot></slot>
      </main>
    </div>
    <footer class="bg-app-gradient flex-shrink">
      <div class="container mx-auto max-w-3xl px-4 subpixel-antialiased text-white py-2">
        <div class="flex items-center">
          <div class="mr-2">
            <IconLogo class="w-12 h-12"></IconLogo>
          </div>
          <div class="leading-4">
            Photochromic <br/> <small>&copy; {{ new Date().getFullYear() }}</small>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import TheConnectedElement from "@/components/TheConnectedElement";
import IconLogo from "@/components/IconLogo";

export default {
  components: { IconLogo, TheConnectedElement },
  props: {},
  setup()
  {

  }
}
</script>