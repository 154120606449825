import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/index.css'

import router from './router/routes'
import axios from 'axios'

axios.defaults.baseURL = 'https://api.photochromic.io/v1'
// axios.defaults.baseURL = 'http://api.photochromic.loc/v1'

const app = createApp(App)
app.use(router)
app.mount('#app')
