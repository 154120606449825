<template>
  <div>
    <div v-if="type === 'neutral'" class="flex flex-col items-center">
      <div class="h-12 w-12 bg-gray-300 text-gray-200 rounded-full flex items-center justify-center">
        -
      </div>
      <div class="text-gray-200 text-xs">even</div>
    </div>
    <div v-if="type === 'for'" class="flex flex-col items-center">
      <div class="h-12 w-12 bg-success-400 text-success-200 rounded-full flex items-center justify-center">
        <ThumbUpIcon class="h-6 w-6"></ThumbUpIcon>
      </div>
      <div class="text-success-700  text-xs">{{ formattedScore }}</div>
    </div>
    <div v-if="type === 'against'" class="flex flex-col items-center">
      <div class="h-12 w-12 bg-danger-400 text-danger-200 rounded-full flex items-center justify-center">
        <ThumbDownIcon class="h-6 w-6"></ThumbDownIcon>
      </div>
      <div class="text-danger-700  text-xs">{{ formattedScore }}</div>
    </div>
  </div>
</template>
<script>
import { ThumbUpIcon, ThumbDownIcon } from '@heroicons/vue/outline'
import { computed } from "vue";
import { abbreviateNumber } from "@/utils/numbers";

export default {
  props: {
    score: {
      type: Number,
      default: 0
    },
  },
  components: {
    ThumbUpIcon, ThumbDownIcon
  },
  setup(props)
  {
    const type = computed(() => {
      if (props.score > 0) {
        return 'for'
      }
      if (props.score < 0) {
        return 'against'
      }

      return 'neutral'

    })

    const formattedScore = computed(() => {
      if (props.score > 0) {
        return '+' + abbreviateNumber(props.score)
      }
      if (props.score < 0) {
        return '-' + abbreviateNumber(props.score)
      }
      return '-';
    })

    return { type, formattedScore }
  }
}
</script>