<template>
  <div v-if="!connectedAccount">
    <router-link to="/connect">
      <div class="rounded-full bg-gray-50 p-2 flex text-gray-400 justify-between items-center shadow  ring-hover">
        <div class="rounded-full h-12 w-12 bg-gray-100 mr-4  flex items-center  justify-center text-xs">...</div>
        <div class="mr-4 italic font-light">Not connected</div>
      </div>
    </router-link>
  </div>
  <div v-if="connectedAccount">
    <button @click="showDisconnectMessage">
      <div class="rounded-full bg-gray-200 p-2 flex justify-between items-center shadow ring-hover font-ubuntu">
        <div class="rounded-full h-12 w-12 bg-app-gradient mr-4 flex items-center justify-center text-white text-xs">{{ last4 }}</div>
        <div class="mr-4 text-gray-600">
          <h3 class="text-gray-900 font-light">Wallet <span class="font-bold">Connected</span></h3>
          <h4 class="font-mono tracking-wider">{{ connectedAccount }}</h4>
        </div>
      </div>
    </button>
  </div>
</template>
<script>
import { computed, inject, onMounted } from "vue";
import connection from "../utils/crypto/connection";

const emitter = require('tiny-emitter/instance');

export default {
  props: {},
  setup()
  {
    const connectedAccount = inject('connectedAccount', null)

    onMounted(async () => {

      if (connectedAccount.value) {

        let acc = await connection.getAccount();
        console.log(acc);
      }
    })

    const last4 = computed(() => {
      if (connectedAccount.value) {
        return '..' + connectedAccount.value.slice(-4)
      }
      return ''
    })

    const showDisconnectMessage = () => {

      emitter.emit('fire-alert', 'Looking to disconnect or switch account?', 'info', 'You can administer account switching and disconnecting directly from MetaMask', 18000)
    }

    return { connectedAccount, last4, showDisconnectMessage }
  }
}
</script>
<style scoped>
.ring-hover {
  @apply hover:ring-secondary-300 hover:ring-2 hover:ring-offset-4 hover:ring-offset-secondary-100;
}
</style>