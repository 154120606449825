import { createRouter, createWebHistory } from 'vue-router'
import Proposals from "@/views/Proposals";
import Connect from "@/views/Connect";
import ProposalView from "@/views/ProposalView";
import ProposalVotes from "@/views/ProposalVotes";
import NotFound from "@/views/NotFound";


const routes = [
    { path: '/', component: Proposals, name: 'proposals' },
    { path: '/connect', component: Connect, name: 'connect' },
    { path: '/proposal/:id/view', component: ProposalView, name: 'proposal-view' },
    { path: '/proposal/:id/votes', component: ProposalVotes, name: 'proposal-votes' },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})


export default router;