<template>
  <div>
    <div v-if="!isLoading">
      <div v-if="hasMetaMask" class="mt-12">
        <div class='flex flex-col items-center'>
          <div>This site is for Photochromic token holders</div>
          <button class='btn-secondary' @click="connect">Connect your MetaMask</button>
        </div>
      </div>
      <div v-if="!hasMetaMask">
        <div class='flex flex-col items-center'>
          <div>You will need MetaMask to interact with this site:</div>
          <div>
            <a href="https://metamask.io/" target="_blank" rel="noreferrer" class='border rounded p-4 block hover_pc_bg hover:border-transparent'>
              <div class="px-4 py-4 flex items-center sm:px-6">
                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <p class="font-medium text-indigo-600 truncate">MetaMask</p>
                </div>
                <div class="ml-5 flex-shrink-0">
                  <img src="@/assets/svg/metamask.svg" class="w-8 h-8" alt="MetaMask" >
                </div>
              </div>

            </a>
          </div>
        </div>
      </div>
    </div>
    <AppLoader v-if="isLoading">Loading connector</AppLoader>
  </div>
</template>
<script>
import { inject, onMounted, ref } from "vue";
import connection from '../utils/crypto/connection'
import AppLoader from "@/components/AppLoader";

export default {
  components: { AppLoader },
  props: {},
  setup(props, { emit })
  {
    const emitter = require('tiny-emitter/instance');
    const hasMetaMask = ref(false)
    const isLoading = ref(true)
    const connectedAccount = inject('connectedAccount')
    const router = inject('router')

    async function isConnected()
    {
      //Is connected?
      let connectedAccount = await connection.getAccount();
      if (connectedAccount) {
        emitter.emit('fire-alert', 'Connected', 'success')
        return router.push('/')
      }
    }

    async function connect()
    {
      let connect = await connection.connect()
      if (connect) {
        emit('route', 'contract')
      }
      await isConnected();
    }

    onMounted(async () => {

      if (!connectedAccount.value) {
        //
      }
      await isConnected();

      hasMetaMask.value = await connection.checkMetamask()
      isLoading.value = false
    })

    return { connect, hasMetaMask, isLoading }

  }
}
</script>